/* eslint-disable import/prefer-default-export
 */
export const AUTH0_SCOPE =
  'openid email profile offline_access scid:read:profile scid:update:profile scid:update:preferences scid:verify:myinfo scid:update:account_security scid:read:qr';
export const AUTH_TOKEN_KEY = 'auth_token';
export const REDIRECT_URL = 'redirect_url';
export const GUEST_DATA = 'guest_data';
export const CUSTOMER_UID = 'customerUid';
export const LAST_ACTIVITY = 'lastActvity';
export * from './payments';
export * from './general';
export const DEFAULT_TITLE = 'FairPrice E-Gift Voucher';

export const B2B = 'B2B';
export const B2C = 'B2C';
export const SITE_TYPE = 'SITE_TYPE';

export const EVENT_PROPERTY = 'Event Property';
export const EVENT_GIFTING_CART = 'egifting | cart';

export const REFRESH_BEFORE_EXPIRY = 15 * 60 * 1000;
export const TOKEN_UPDATE_INTERVAL = 5 * 60 * 1000;
