module.exports = {
  ADD_CREDIT_DEBIT: 'Add credit/debit card',
  CREDIT_DEBIT_CARDS: 'Credit/Debit Cards',
  QUICK_BUY_TITLE_LINE:
    'With QuickBuy@SGov, public officers can now easily make small value purchases online.',
  ADD_GOV_AGENCY: 'Add government agency',
  QUICK_BUY_TITLE: 'QuickBuy@SGov',
  GOV_EMAIL: 'Government agency email',
  VERIFY_EMAIL: 'Verify email',
  CANCEL: 'Cancel',
  LAUNCH_WORKPAL: 'Next, launch your WorkPal app to authenticate this request within 2 mins',
  HOW_AUTHENCTICATE: 'How to authenticate',
  REQUEST_CANCELLED: 'You cancelled your request',
  REQUEST_EXPIRED: 'Your request has been expired. Verify email again.',
  EMAIL_VERIFIED: 'Email Verified!',
  INVALID_EMAIL: 'Unable to find this email. Try again',
  EXPIRY_DURATION: 120000,
  API_INTERVAL: 10000,
  PAYMENT_SUCCESS_STATUS: '3Fo62107CnTbrKWaNJ4Vh9iRIdtfOHyu',
  PAYMENT_FAILURE_STATUS: 'VIRLFiATKhfGdE6PS1Ceqvg8bDNtljpy',
};
