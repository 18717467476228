/* eslint-disable import/prefer-default-export */
import nookies from 'nookies';
import { AUTH_TOKEN_KEY, CUSTOMER_UID, GUEST_DATA, REDIRECT_URL } from '../../constants/index';
import ErrorUnauthorized from './errorUnauthorized';

const createFetchFor =
  (init) =>
  async (url, params = {}) => {
    const { headers: HeaderParams = {}, ...rest } = params;
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        ...HeaderParams,
      },

      ...init,
      ...rest,
    });
    if (!response.ok) {
      if (response.status === 401) {
        nookies.destroy({}, REDIRECT_URL);
        nookies.destroy({}, GUEST_DATA);
        nookies.destroy({}, CUSTOMER_UID);
        nookies.destroy({}, AUTH_TOKEN_KEY);
        throw new ErrorUnauthorized('Request Unauthorized. Please try again.');
      }
      const error = await response.json();
      //  Build an API error object
      const apiError = new Error(error.message || 'Request failed. Please try again.');
      apiError.name = 'ApiError';
      apiError.code = error.errorCode;
      apiError.type = error.errorType;
      apiError.status = response.status;
      apiError.ok = false;
      throw apiError;
    }
    return response.json();
  };

export const get = createFetchFor({ method: 'GET' });
export const post = createFetchFor({ method: 'POST' });
export const put = createFetchFor({ method: 'PUT' });
export const patch = createFetchFor({ method: 'PATCH' });
export const del = createFetchFor({ method: 'DELETE' });
